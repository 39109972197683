<template>
  <div>
    <ed-modal
      v-model="bind.boolExibirDadosAlterados"
      width="100%"
      v-if="formData"
    >
      <div slot="title">Conteúdo :: {{ formData.intId }}</div>

      <div class="" slot="content">
        <h5>Dados de Envio</h5>
        <pre>{{ formData.strUrlDestinoConteudo }}</pre>

        <h5>Dados de Retorno</h5>
        <pre>{{ formData.strMensagem }}</pre>
      </div>
    </ed-modal>

    <ed-table
      :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @search="getLog"
      @filter="getLog"
      disabledRegisterRows
      disabledEditRows
      disabledDeleteRows
    >
      <template slot="table-filter">
        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.strNomeDatabase"
          label="Cliente"
          name="strNomeDatabase"
          item-text="strNome"
          item-value="strNomeDatabase"
          route="System/Cliente"
          :filters="{}"
          clearable
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data de Execução"
          name="arrayDataExecucao"
          v-model="formFiltros.arrayDataExecucao"
          clearable
          multiple
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Status"
          name="intStatus"
          v-model="formFiltros.intStatus"
          :items="[
            { intId: 0, strNome: 'PENDENTE' },
            { intId: 1, strNome: 'PROCESSANDO' },
            { intId: 2, strNome: 'PROCESSADO' },
            { intId: 3, strNome: 'FALHA' },
            { intId: 4, strNome: 'CANCELADO' },
            { intId: 5, strNome: 'TIMEOUT' },
          ]"
          clearable
        />
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputText,
  EdInputDate,
  EdInputSelect,
  EdInputCheckbox,
  EdButton,
} from "@/components/common/form";
export default {
  name: "EdModelo",
  props: {},
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputText,
    EdInputDate,
    EdInputSelect,
    EdInputCheckbox,
    EdButton,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolExibirDadosAlterados: false,
      },
      loading: false,
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
        },
        {
          text: "Base",
          sortable: true,
          value: "strNomeDatabase",
        },
        {
          text: "Comando",
          sortable: true,
          value: "strComando",
        },
        {
          text: "Status",
          sortable: true,
          value: "intStatus",
        },
        {
          text: "Url",
          sortable: true,
          value: "strUrlDestino",
        },
        {
          text: "Data de Excução",
          sortable: true,
          value: "strDataExecucao",
        },
        {
          text: "Data Cadastro",
          sortable: true,
          value: "strDataCadastro",
        }
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        boolLimparCache: 0,
        strNomeDatabase: null,
        strComando: null,
        arrayDataExecucao: [],
        intStatus: null,
      },
      formData: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.getLog();
    },

    getDadosAlterados(item) {
      this.formData = Object.assign(item, {});
      this.bind.boolExibirDadosAlterados = !this.bind.boolExibirDadosAlterados;
    },

    getLog() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Fila", this.formFiltros)
        .then((objResult) => {
          this.loading = false;
          console.log("objResult", objResult);

          if (objResult.status == 200 && objResult.result) {
            this.objPagination = objResult.result;

            let arrayRows = [];

            objResult.result.data.forEach((objModelo) => {
              let intStatus = objModelo.intStatus;

              switch (Number(objModelo.intStatus)) {
                case 0:
                  intStatus = "Pendente";
                  break;

                case 1:
                  ntStatus = "Processando";
                  break;

                case 2:
                  intStatus = "Processado";
                  break;

                case 3:
                  intStatus = "Falha";
                  break;

                case 4:
                  intStatus = "Cancelado";
                  break;

                case 5:
                  intStatus = "Timeout";
                  break;
              }
              let item = {
                intId: objModelo.intId,
                strNomeDatabase: objModelo.strNomeDatabase,
                strComando: objModelo.strComando,
                intStatus: intStatus,
                strUrlDestino: objModelo.strUrlDestino,
                strUrlDestinoConteudo: objModelo.strUrlDestinoConteudo,
                strDataExecucao: this.$utilidade.toDate(
                  objModelo.strDataExecucao
                ),
                strDataCadastro: this.$utilidade.toDate(
                  objModelo.strDataCadastro
                ),
                buttons: [],
                _item: objModelo,
              };

              if (objModelo.strUrlDestinoConteudo || objModelo.strMensagem) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("logs"),
                  color: "warning",
                  title: "Visualizar dados alterados",
                  click: this.getDadosAlterados,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
